<template>
  <div class="homepage_bg_content">
    <div class="mine_content">
      <Tologin></Tologin>
    </div>
  </div>
</template>

<script>

import Tologin from "@/components/toLogin"

export default {
  name: 'my_index',
  components: { Tologin },
  data() {
    return {

    }
  },
  mounted() {

 
  },
  methods: {






  }
}
</script>

<style lang="css" scoped>

.homepage_bg_content {
    background: #ffffff;
}
.mine_content{
    margin: 100px auto;
}
</style>
<template>
  <div class="login_box">
    <small>您还没有登录，请点击登录</small>
    <el-button type="primary" @click="goLogin" size="mini">去登录</el-button>
  </div>
</template>

<script>
import { getWechatLoginUrl } from "@/utils/login.js"

export default {
  name: 'tologin',
  data() {
    return {

    }
  },
  methods: {
    goLogin() {
      const { redirect = '/' } = this.$route.query
      const target = redirect ? redirect : this.$route.fullPath
      const loginUrl = getWechatLoginUrl(target)
      window.location.href = loginUrl
    }
  }
}
</script>

<style lang="css" scoped>
.login_box {
  text-align: center;
  width: 300px;
  margin: 0 auto;
}
</style>